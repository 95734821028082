import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Import Components
import AuthenticatedApp from 'Auth';
import componentLoader from 'utils/componentLoader';
import DynamicComponent from 'utils/DynamicComponent';

const Login = lazy(() =>
  componentLoader(() =>
    import(
      /* webpackChunkName: "login-page", webpackPrefetch: true */
      'containers/Login'
    ),
  ),
);

const Paywall = lazy(() =>
  componentLoader(() =>
    import(
      /* webpackChunkName: "login-page", webpackPrefetch: true */
      'containers/Paywall'
    ),
  ),
);

const PrivateRoutes = lazy(() =>
  componentLoader(() =>
    import(
      /* webpackChunkName: "main-routes", webpackPrefetch: true */
      'Routes'
    ),
  ),
);

/**
 *
 * @returns {React.Component} Authenticated app component or Login component
 */
const MainRoute = () => (
  <Switch>
    <Route
      path="/"
      exact
      render={() => (
        <DynamicComponent fullpage>
          <Login />
        </DynamicComponent>
      )}
    />
    <Route
      // path="/paywall"
      path="/pricing"
      exact
      render={() => (
        <DynamicComponent fullpage>
          <Paywall />
        </DynamicComponent>
      )}
    />
    <AuthenticatedApp privateRoutes={PrivateRoutes} />
    <Route render={() => <Redirect to="/dashboard/page_not_found" />} />
  </Switch>
);

export default MainRoute;
